@font-face {
  font-family: 'Neue Montreal';
  src: url('./NeueMontreal-Bold.woff2') format('woff2'), url('./NeueMontreal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('./NeueMontreal-Regular.woff2') format('woff2'), url('./NeueMontreal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk SemiMono';
  src: url('./MonumentGrotesk-SemiMono.woff2') format('woff2'), url('./MonumentGrotesk-SemiMono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

